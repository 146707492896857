@tailwind base;

@tailwind components;

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blurred {
  color: transparent;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.blurred:hover {
  color: inherit;
  text-shadow: none;
}

del {
  @apply p-0.5 text-red-700 bg-red-100;
}

ins {
  @apply p-0.5 text-green-700 bg-green-100;
}

.warning .progress {
  @apply bg-gradient-to-r from-yellow-400 to-yellow-500;
}

.success .progress {
  @apply bg-gradient-to-r from-green-500 to-green-600;
}

.danger .progress {
  @apply bg-gradient-to-r from-red-500 to-red-600;
}

.warning.potential {
  @apply bg-yellow-300;
}

.success.potential {
  @apply bg-green-400;
}

.danger.potential {
  @apply bg-red-400;
}

.warning.potential-gradient {
  @apply bg-gradient-to-r from-yellow-300 to-yellow-100;
}

.success.potential-gradient {
  @apply bg-gradient-to-r from-green-400 to-green-100;
}

.danger.potential-gradient {
  @apply bg-gradient-to-r from-red-400 to-red-100;
}

.warning .progress-percent {
  @apply text-yellow-50;
}

.success .progress-percent {
  @apply text-green-50;
}

.danger .progress-percent {
  @apply text-red-50;
}

.progress,
.potential {
  animation: slide 0.4s cubic-bezier(0.75, 0.79, 0.75, 1.09);
}

.bg-stripes-white {
  --stripes-color: hsla(0, 0%, 100%, 0.4);
}

.bg-stripes {
  background-image: linear-gradient(
    -45deg,
    var(--stripes-color) 25%,
    transparent 25%,
    transparent 50%,
    var(--stripes-color) 50%,
    var(--stripes-color) 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 12px 12px;
  animation: move 2s linear infinite;
}

.text-shadow {
  text-shadow: 0 0 12px #6c6c6c;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 12px 12px;
  }
}

@keyframes slide {
  0% {
    width: 0;
  }
}

.ql-toolbar.ql-snow {
  @apply border-gray-300 rounded-t-md;
}

.ql-container.ql-snow {
  @apply font-sans border-gray-300 rounded-b-md;
}

.ql-editor {
  @apply text-sm leading-6 min-h-64;
}

.error .ql-toolbar {
  @apply text-red-900 border-red-300;
}

.error .ql-container {
  @apply text-red-900 border-red-300;
}

.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  @apply stroke-current;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  @apply fill-current;
}

.error .ql-stroke,
.error .ql-fill,
.error .ql-picker {
  @apply text-red-700;
}

div.Toastify__toast--success {
  @apply bg-gradient-to-r from-green-500 to-green-400;
}

div.Toastify__toast--error {
  @apply bg-gradient-to-r from-red-500 to-red-400;
}

div.Toastify__toast--warning {
  @apply bg-gradient-to-r from-yellow-500 to-yellow-400;
}

div.Toastify__toast--info {
  @apply bg-gradient-to-r from-blue-500 to-blue-400;
}

div.Toastify__toast {
  @apply sm:rounded-md;
}

@tailwind utilities;
